import { render, staticRenderFns } from "./index.vue?vue&type=template&id=5058c390"
import script from "./index.vue?vue&type=script&lang=ts"
export * from "./index.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BannerReviewToCollect: require('/home/runner/work/webapp/webapp/components/molecules/banner/BannerReviewToCollect.vue').default,BannerPopupStore: require('/home/runner/work/webapp/webapp/components/molecules/banner/BannerPopupStore.vue').default,EditoBlocks: require('/home/runner/work/webapp/webapp/components/organisms/edito/EditoBlocks.vue').default,BaseLink: require('/home/runner/work/webapp/webapp/components/atoms/link/BaseLink.vue').default,ModalNewsletter: require('/home/runner/work/webapp/webapp/components/organisms/modal/ModalNewsletter.vue').default})
